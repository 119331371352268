<template>
    <div class="tableTd" @mouseover="_over" @mouseleave="_out">
        <span  v-for="item in infoData.text[0]" class="cellText"><a-tag color='blue' v-if="item.UserName && item.UserName !=''">{{item.UserName}}</a-tag></span>
<!--         <a-popconfirm title="您确定要参加吗?"  @confirm="_join(infoData.text[0][0])" okText="确定" cancelText="取消">
            <a-button v-if="showBtn" type="primary"  @mouseover="_over" >参加</a-button>
        </a-popconfirm> -->
        <a-button v-if="showBtn" type="primary"  @mouseover="_over" @click="_join(infoData.text[0][0])">参加</a-button>
        <a-button v-if="isUser"  @mouseover="_over" @click="_cancel(infoData.text[0][0])">取消</a-button>
    </div>
</template>

<script>
    
    export default {
        name: "tableTd",
        inject: ['reload'],
        props: {
            info: Object
        },
        data(){
            return {
                userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')),
                infoData: this.info,
                showBtn: false,
                isUser: false,
            }
        },
        mounted(){
            // console.log(this.infoData);
        },
        methods: {
            _over(){

                this.showBtn = true;
                let user_num = 0;
                if (this.infoData.text[0].length != 0){
                    this.infoData.text[0].map(res=>{
                        if (res.UserId){
                            if(res.UserId == this.userInfo.uid){
                                user_num ++;
                                this.isUser = true;
                                this.showBtn = false;
                            }
                        }

                    });
                }
                if (user_num>0) {
                    this.isUser = true;
                }

            },
            _out(){
                this.showBtn = false;
                this.isUser = false;
            },
            _join(item){
                let that = this;
                this.$axios.post(8000115, {
                    SchoolId: item.SchoolId,
                    SchoolName: item.SchoolName,
                    TheDate: item.TheDate,
                    SupportItem: item.SupportItem,
                }, res => {
                    if (res.data.code == 1) {
                        let tabs = this.infoData.text;
                        tabs[0].push({UserName:this.userInfo.name,UserId:this.userInfo.uid});
                        // console.log(tabs);
                        this.$set(this.infoData,'text',tabs);
                        this.$message.success(res.data.msg);
                        this.isUser = true;
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                });
            },
            _cancel(item){
                this.$axios.post(8000116, {
                    SchoolId: item.SchoolId,
                    TheDate: item.TheDate,
                    SupportItem: item.SupportItem,
                }, res => {
                    if (res.data.code == 1) {
                        let tabs = this.infoData.text;
                        for( let user in tabs[0]){
                            console.log(tabs[0][user]);
                            if (tabs[0][user].UserId == this.userInfo.uid) {
                                tabs[0][user].UserName = '';
                                tabs[0][user].UserId = '';
                            }
                        }
                        // console.log(tabs);
                        this.$set(this.infoData,'text',tabs);
                        this.isUser = false;
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.warning(res.data.msg)
                    }
                });

                // this.reload();

            }
        }
    }
</script>

<style scoped lang="less">
    .tableTd{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        button{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .cellText{
        text-align: center;
        display:block;
    }
</style>
