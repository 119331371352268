<template>
    <div class="SupportSchool">
        <div class="search">
            <a-tree-select style="width: 200px;margin-right:10px" :maxTagCount="1" :treeData="treeDataSchool" treeNodeFilterProp="title" :value="schoolVal" @change="onChangeSchool" treeCheckable :showCheckedStrategy="SHOW_PARENT" placeholder="请选择中心"/>
            <a-month-picker class="search-style" placeholder="请选择月份" v-model="month" @change="onChangeMonth"/>
            <a-button class="search-style" @click="_clear">重置</a-button>
            <a-button type="primary" @click="_search">查询</a-button>
        </div>
        <!---->
        <div :style="'width:' +($store.state.app.showNav ? pageWidth - 220 : pageWidth) +'px;overflow: auto'">
            <a-table :pagination="false" :columns="columns" bordered :data-source="infoData" :scroll="{ x:3000, y: 600 }">
<!--                 <div v-for="items in columns">
                    <div :slot="item.key" slot-scope="text, record, index" v-for="item in items.children">
                        <span style="font-size: 15px;">{{text}}</span>
                        <a-popconfirm title="您确定要参与吗?" @confirm="join_support(record.SchoolId,record.OrgName,record.TheDate,item.key,index)" okText="确定" cancelText="取消">
                            <a-button :type="button_type" v-show="button_show">{{button_text}}</a-button>
                        </a-popconfirm>
                    </div>
                </div> -->
            </a-table>
        </div>
    </div>
</template>

<script>
    import {TreeSelect} from 'ant-design-vue';
    import tableTd from './tableTd';
    export default {
        name: 'SupportSchool',
        components: {tableTd},
        data() {
            return {
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                columns: [],
                treeDataSchool: [],
                schoolVal: ['4ae815d133588c71013358ad96450007'],
                SHOW_PARENT: TreeSelect.SHOW_PARENT,
                infoData: [],
                month: null,


            }
        },
        created() {
            this.pageWidth = document.documentElement.clientWidth - 220;

            //获取当前月
            let myDate = new Date();
            let year = myDate.getFullYear();
            let _month = myDate.getMonth() + 1;
            this.month = _month<10 ? (year + '-0' + _month) : (year + '-' + _month);

            //获取中心
            this.$axios.get(8008, {}, res => {
                if (res.data.code == 1) {
                    this.treeDataSchool = res.data.data;
                } else {
                    this.$message.errpr(res.data.msg)
                }
            });
            this._tableInfo();
        },
        methods: {
            _tableInfo(){
                this.$message.loading('查询中');
                this.$axios.post(8000114, {SchoolId: this.schoolVal, Month: this.month,}, res => {
                    if (res.data.code == 1) {
                        this.$message.destroy();
                        this.columns = res.data.column;
                        this.columns.map((item,index)=>{
                        	//console.log(item)
                        	if(item.children){
                        		item.children.map(tab=>{
                        			tab.customRender = function(text, row, index){
                        			    let data = {
                        			        text: text,
                                            row: row
                                        };
                                        return <tableTd info={data}></tableTd>
                        			}
                        		})
                        	}
                        });
                        this.infoData = res.data.user;
                    } else {
                        this.$message.errpr(res.data.msg)
                    }
                });
            },
            onChangeSchool(value) {
                this.schoolVal = value;
            },
            onChangeMonth(val) {
                let census_month = new Date(val._d)
                let month = census_month.getFullYear() +
                    '-' +
                    (census_month.getMonth() + 1 < 10
                        ? '0' + (census_month.getMonth() + 1)
                        : census_month.getMonth() + 1)
                this.month = month;
            },

            _treeData() {
                let self = this
                this.$axios.get(
                    1090,
                    {
                        UserId: self.userinfo.uid
                    },
                    res => {
                        self.treeData = res.data
                    }
                )
            },

            _search() {
                this._tableInfo();
            },
            _clear() {
                this.month = '';
                this.schoolVal = [];
                this._search();
            },
            ChangeSearchTime(time, timeString) {
                // this.createValue = time;
                this.StartDate = timeString[0]
                this.EndDate = timeString[1]
            },
        }
    }
</script>

<style scoped lang="less">
    .SupportSchool {
        background: #ffffff;
        margin-top: 15px;
        min-height: 800px;
        padding: 15px;
    }

    .search {
        margin-bottom: 20px;
        display: flex;
        .search-style {
            /*display: inline-block;*/
            width: 120px;
            margin-right: 15px;
        }
    }

    .SupportSchool .ant-table-tbody > tr > td {
        padding: 0 !important;
    }

    .tableData {
        margin-top: 50px;
    }
</style>
